<template>
    <div class="flex flex-col mx-4 h-screen">    
        <div class="flex-auto">
            <img class="md:mx-20 my-4 h-28 md:h-32" :src="require('../../assets/logo.png')" />
        </div>
        <div class="flex-auto md:mx-20">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 md:col-span-12">
                    <p class="text-gray-800 font-normal text-xl md:text-2xl mb-8 md:mb-12">
                        Estamos quase lá.<br/>Precisamos de mais algumas informações, mas não se preocupe<br/> pois <b>só usaremos para personalizar o seu período de testes.</b>
                    </p>
                    <label for="name" class="font-semibold text-lg text-gray-800 pb-2 block">Digite seu nome completo</label>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-8 2xl:col-span-4">
                            <input v-model="form.name" placeholder="Primeiro Nome" type="text" name="name" id="name" autocomplete="off" autocorrect="off" class="border-none bg-gray-200 focus:ring-gray-800 px-3 py-3 mb-3 text-base w-full" />
                        </div>
                    </div>
                    <label for="name" class="font-semibold text-lg text-gray-800 pb-2 block mt-12">Agora, defina sua senha</label>
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-4">
                             <input v-model="form.password" placeholder="Senha" :type="showpassword ? 'text' : 'password'" name="password" id="password" autocomplete="off" class="border-none bg-gray-200 focus:ring-gray-800 px-3 py-3 text-base w-full" />
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="name" @click="showpassword = !showpassword" class="font-bold text-lg text-terciary pb-2 block md:mt-3 cursor-pointer">{{ showpassword ? 'Esconder senha' : 'Mostrar senha' }}</label>
                        </div>
                    </div>
                    <label for="name" class="font-semibold text-lg text-gray-800 pb-2 block mt-12">E qual seu telefone</label>
                    <div class="grid grid-cols-12 gap-4 mb-12 md:mb-2">
                        <div class="col-span-12 md:col-span-4">
                            <the-mask :mask="['(##) ####-####', '(##) #####-####']" v-model="form.phone" placeholder="(99) 99999-9999" type="text" name="phone" id="phone" autocomplete="off" class="border-none bg-gray-200 focus:ring-gray-800 px-3 py-3 text-base w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-auto items-end">
            <div class="float-left">
                <progress-bar class="md:mx-20" :options="options" :value="value"/> 
                <label class="md:mx-20 block text-base font-medium text-center">
                    Passo 3 de 4
                </label>
            </div>
            <button @click="finalizar" type="button" class="float-right md:mr-10 transition duration-200 bg-yellow-400 hover:bg-yellow-500 focus:bg-yellow-800 focus:shadow-sm focus:ring-4 focus:ring-gray-800 focus:ring-opacity-50 text-white px-8 py-4 text-sm md:text-base shadow-sm hover:shadow-md font-semibold text-center inline-block">
                <span class="inline-block mr-2">Continuar</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            consultoria: false, 
            empresa: false,
            showpassword: false,
            form: {
                _id: '',
                name: '',
                password: '',
                phone: '',
            },
            value: 75,
            options: {
                text: {
                    color: '#FFFFFF',
                    shadowEnable: false,
                    shadowColor: '#000000',
                    fontSize: 14,
                    fontFamily: 'Helvetica',
                    dynamicPosition: false,
                    hideText: false
                },
                progress: {
                    color: '#FBBF24',
                    backgroundColor: '#CCCC',
                    inverted: false
                },
                layout: {
                    height: 30,
                    width: 140,
                    verticalTextAlign: 61,
                    horizontalTextAlign: 43,
                    zeroOffset: 0,
                    strokeWidth: 30,
                    progressPadding: 0,
                    type: 'line'
                }
            }
        }
    },
    methods: {
        async finalizar () {

            if(!this.form.name) return this.$vToastify.error("Digite seu nome");
            if(!this.form.password) return this.$vToastify.error("Digite sua senha");
            if(!this.form.phone) return this.$vToastify.error("Digite seu telefone");

            const resp = await this.$http.post("/finalizar", this.form);
            if (!resp.data.success) {
                this.$vToastify.error(resp.data.err);
            } else {
                localStorage.setItem('token', resp.data.token);
                localStorage.setItem('user', JSON.stringify(resp.data.user));
                this.$store.commit('login', resp.data.user);
                this.$router.push({path:'/informacoes'});
            }
        }
    },
    mounted() {
        this.form._id = this.$route.params.id;
    },
}
</script>